<template>
  <v-app-bar
    fixed
    v-scroll="onScroll"
    height="100"
    style="z-index: 99999"
    :class="isScrolling ? 'scrolled-appbar' : 'unscrolled-appbar'"
    v-if="$vuetify.breakpoint.mdAndUp"
    color="#EEEDEE"
    flat
  >
      <v-img
      v-if="offsetTop > 100"
      class="appbar-logo"
      contain
      width="170"
      height="65"
      @click.prevent="$router.push('/')"
      src="/static/logo_stc.png"
      style="cursor: pointer"
    ></v-img>
    <v-spacer v-else></v-spacer>
    
    <v-toolbar-items style="background-color: #ecebec">
      <v-btn
        href="/"
        text
        class="toolbar-btn-light1"
        @click.prevent="$router.push('/')"
        >{{ texts.menu.home }}</v-btn
      >
      <v-btn
        href="/transportes"
        text
        class="toolbar-btn-light"
        @click.prevent="$router.push('/transportes')"
        >{{ texts.menu.who }}</v-btn
      >
      <v-btn
        href="/reciclaje"
        text
        class="toolbar-btn-light"
        @click.prevent="$router.push('/reciclaje')"
        >{{ texts.menu.manuServ }}</v-btn
      >
      <v-btn
        href="/confinamiento"
        text
        class="toolbar-btn-light"
        @click.prevent="$router.push('/confinamiento')"
        >{{ texts.menu.comServ }}</v-btn
      >
      <v-btn
        href="/faq"
        text
        class="toolbar-btn-light"
        @click.prevent="$router.push('/faq')"
        >{{ texts.menu.faq }}</v-btn
      >
      <v-btn
        href="/contact"
        text
        class="toolbar-btn-light"
        @click.prevent="$router.push('/contact')"
        >{{ texts.menu.contact }}</v-btn
      >
    </v-toolbar-items>
    <v-spacer></v-spacer>
  </v-app-bar>
  <v-app-bar
    v-else
    v-scroll="onScroll"
    height="100"
    style="z-index: 99999"
    :class="isScrolling ? 'scrolled-appbar' : 'unscrolled-appbar'"
    color="#EEEDEE"
    flat
  >
    <v-layout row wrap justify-center align-center>
      <v-flex xs3>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn style="z-index: 999" icon v-on="on">
              <v-icon color="black">mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-card color="#772336" dark style="width: 100vh">
            <v-list color="transparent">
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/')"
                  v-text="texts.menu.home"
                  :style="'cursor: pointer; font-weight: bold; color:white; text-transform: capitilize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/transportes')"
                  v-text="texts.menu.who"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/reciclaje')"
                  v-text="texts.menu.manuServ"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/confinamiento')"
                  v-text="texts.menu.comServ"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/faq')"
                  v-text="texts.menu.contact"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
              <hr
                style="
                  height: 0.1px !important;
                  border-color: rgba(255, 255, 255, 0.1) !important;
                "
              />
              <v-list-item>
                <v-list-item-title
                  @click.prevent="$router.push('/contact')"
                  v-text="texts.menu.contact"
                  :style="'cursor: pointer; font-weight: bold;color:white; text-transform: capitalize; text-align: center; font-family: ´MonsMontserrat´, sans-serif'"
                />
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-flex>
      <v-flex xs9>
        <v-img style="max-width: 50%" src="/static/logo_stc.png"></v-img>
      </v-flex>
    </v-layout>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapMutations } from "vuex";
import page_texts from "../../views/guests/guestTexts";
export default {
  data: () => ({
    isScrolling: false,
    showOptions: false,
  }),
  computed: {
    language() {
      // iniciatlize global language for this section ( 0 = spanish, 1 = english)
      return this.$store.state.language;
    },
    texts() {
      // return the texts JSON based on the language chosen
      // if more languages are added, convert into a switch with param (this.language)
      return this.language == 0 ? page_texts.spanish : page_texts.english;
    },
          offsetTop () {
        return this.$store.state.offsetTop
      }
  },
  methods: {
    toggle_language(value) {
      this.$store.commit("toggle_language", value);
    },
    ...mapMutations(["toggleDrawer"]),
    onScroll() {
      this.isScrolling =
        (window.pageYOffset || document.documentElement.scrollTop || 0) > 600;
    },
    burgerClick() {
      this.showOptions = !this.showOptions;
    },
  },
  mounted() {
  },
};
</script>
<style scoped>

.toolbar-btn-light1{
  margin-top:2%;
  height: 50px !important;
  background-color: #ecebec;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6vh !important;
  color: #772336 !important;
  border-bottom: 2px solid #772336;
}

.toolbar-btn-light {
  margin-top:2%;
  height: 50px !important;
  background-color: #ecebec;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6vh !important;
  color: #772336 !important;
}

.toolbar-btn-light:hover{
    background-color: #ecebec;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6vh !important;
  color: #772336 !important;
  border-bottom: 2px solid #772336;
}

.toolbar-btn {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem !important;
  color: #eeedee !important;
}
.v-app-bar.v-app-bar--fixed {
  top: 0 !important;
}
.appbar-logo {
  margin-left: 5vw;
  max-width: 150px;
  margin-right: 10vw;
}
.scrolled-appbar {
  background-color: #eeedee !important;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.unscrolled-appbar {
  background-color: #eeedee !important;
}
</style>
